import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-school-projecten',
  templateUrl: './school-projecten.component.html',
  styleUrls: ['./school-projecten.component.css']
})
export class SchoolProjectenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
