import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';

import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import { MasterThesisComponent } from './master-thesis/master-thesis.component';
import { KlantgerichteProjectenComponent } from './klantgerichte-projecten/klantgerichte-projecten.component';
import { OverigeProjectenComponent } from './overige-projecten/overige-projecten.component';
import { ZzpProjectenComponent } from './zzp-projecten/zzp-projecten.component';
import { HobbyProjectenComponent } from './hobby-projecten/hobby-projecten.component';
import { SchoolProjectenComponent } from './school-projecten/school-projecten.component';
import { OpleidingComponent } from './opleiding/opleiding.component';
import { SkillsetComponent } from './skillset/skillset.component';
import { WerkervaringComponent } from './werkervaring/werkervaring.component';
import { ContactComponent } from './contact/contact.component';
import { HobbyComponent } from './hobby/hobby.component';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    MasterThesisComponent,
    KlantgerichteProjectenComponent,
    OverigeProjectenComponent,
    ZzpProjectenComponent,
    HobbyProjectenComponent,
    SchoolProjectenComponent,
    OpleidingComponent,
    SkillsetComponent,
    WerkervaringComponent,
    ContactComponent,
    HobbyComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
