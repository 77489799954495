import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import {MasterThesisComponent} from './master-thesis/master-thesis.component';
import {KlantgerichteProjectenComponent} from './klantgerichte-projecten/klantgerichte-projecten.component';
import {OverigeProjectenComponent} from './overige-projecten/overige-projecten.component';
import {ZzpProjectenComponent} from './zzp-projecten/zzp-projecten.component';
import {HobbyProjectenComponent} from './hobby-projecten/hobby-projecten.component';
import {SchoolProjectenComponent} from './school-projecten/school-projecten.component';
import {WerkervaringComponent} from './werkervaring/werkervaring.component';
import {OpleidingComponent} from './opleiding/opleiding.component';
import {SkillsetComponent} from './skillset/skillset.component';
import {ContactComponent} from './contact/contact.component';
import {HobbyComponent} from './hobby/hobby.component';

const routes: Routes =[
    { path: 'home',             component: HomeComponent },
    { path: 'user-profile',     component: ProfileComponent },
    { path: 'register',           component: SignupComponent },
    { path: 'landing',          component: LandingComponent },
    { path: 'login',          component: LoginComponent },
    { path: 'master-thesis',          component: MasterThesisComponent },
    { path: 'klantgerichte-projecten',          component: KlantgerichteProjectenComponent },
    { path: 'overige-projecten',          component: OverigeProjectenComponent },
    { path: 'zzp-projecten',          component: ZzpProjectenComponent },
    { path: 'hobby-projecten',          component: HobbyProjectenComponent },
    { path: 'school-projecten',          component: SchoolProjectenComponent },
    { path: 'werkervaring',          component: WerkervaringComponent },
    { path: 'opleiding',          component: OpleidingComponent },
    { path: 'skillset',          component: SkillsetComponent },
    { path: 'contact',          component: ContactComponent },
    { path: 'hobby',          component: HobbyComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: false
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
