import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overige-projecten',
  templateUrl: './overige-projecten.component.html',
  styleUrls: ['./overige-projecten.component.css']
})
export class OverigeProjectenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
